<script setup >
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import { ref, onMounted, reactive, inject } from "vue";
import axios from "../axios";
import { storedata } from "../store/data";

const Swal = inject('$swal')

import { useStore } from "vuex";
const store = useStore();

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfmake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfmake);

import { Modal } from "bootstrap";

const statuscar = ref([]);
const datacar = ref([]);
const car = ref({});
const car_edit = ref({});
const dataselect = ref();
const car_edit_status = ref('');

const state = reactive({
    modal_IN: null,
})
async function getdata_car(){
  const res = await axios.get(`cartbchange`);
  if(res.data.status == 1){
    let uc1 = 0;let uc2 = 0;let uc3 = 0;let uc4 = 0;
    for (let i = 0; i < res.data.datas.length; i++) {
      let statusvalue = '';
      if(res.data.datas[i].status == 1){uc1++; statusvalue = 'อยู่ระหว่างดำเนินการ';}
      else if(res.data.datas[i].status == 2){uc2++; statusvalue = 'เชื่อมต่อข้อมูลสำเร็จ';}
      else if(res.data.datas[i].status == 3){uc3++; statusvalue = 'ขอข้อมูลเพิ่มเติม';}
      else if(res.data.datas[i].status == 4){uc4++; statusvalue = 'สถานะเปลี่ยนป้ายไม่สำเร็จ';}
      else if(res.data.datas[i].status == 5){uc4++; statusvalue = 'ตรวจสอบข้อมูลแล้วรอการเชื่อมต่อ';}
      let dataimg3 = '';
      if(res.data.datas[i].image_name_3){
        dataimg3 = `${storedata.UrlImages}changeregistration/${res.data.datas[i].tb1}-${res.data.datas[i].tb2}-dis.jpg`;
      }else{
        dataimg3 = 'ไม่มีรูปที่แนบ';
      }
      datacar.value.push([
        res.data.datas[i].id.split(':')[1],
        res.data.datas[i].iddata,
        res.data.datas[i].idv6,
        res.data.datas[i].carnum,
        res.data.datas[i].tb1ori,
        res.data.datas[i].tb2ori,
        res.data.datas[i].tb1,
        res.data.datas[i].tb2,
        `${storedata.UrlImages}changeregistration/${res.data.datas[i].tb1}-${res.data.datas[i].tb2}-tax.jpg`,
        `${storedata.UrlImages}changeregistration/${res.data.datas[i].tb1}-${res.data.datas[i].tb2}-reg.jpg`,
        dataimg3,
        res.data.datas[i].tel,
        res.data.datas[i].dctype == 1 ?'อยู่ในโครงการบัตรส่วนลด':'ไม่อยู่ในโครงการบัตรส่วนลด',
        new Date(res.data.datas[i].insert_at).toLocaleString("en-GB").replace( /,/,"" ),
        new Date(res.data.datas[i].update_at).toLocaleString("en-GB").replace( /,/,"" ),
        statusvalue]);
    }
    statuscar.value = [uc1,uc2,uc3,uc4];
  }
}

onMounted(() => {
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  state.modal_IN = new Modal('#model-in', {})
  state.modal_ED = new Modal('#model-ed', {})
  state.modal_ET = new Modal('#model-et', {})
  getdata_car();
  dataselect.value.dt.on('click', 'button', function (e) {
    let data = dataselect.value.dt.row(e.target.closest('tr')).data();
    get_edit_data(data)
  })
})


function openModalIN(){ state.modal_IN.show()}
function closeModalIN(){state.modal_IN.hide()}
function openModalET(){ state.modal_ET.show()}
function closeModalET(){state.modal_ET.hide()}
function openModalED(){ state.modal_ED.show()}
function closeModalED(){state.modal_ED.hide()}

function imageChanged1(file) {
  const reader = new FileReader();
  reader.onload = async (e) => {
    car.value.image_name1 = e.target.result;
  };
  reader.readAsDataURL(file.target.files[0]);

}

function imageChanged2(file) {
  const reader = new FileReader();
  reader.onload = async (e) => {
    car.value.image_name2 = e.target.result;
  };
  reader.readAsDataURL(file.target.files[0]);

}

function imageChanged3(file) {
  const reader = new FileReader();
  reader.onload = async (e) => {
    car.value.image_name3 = e.target.result;
  };
  reader.readAsDataURL(file.target.files[0]);

}

function imageChanged1edit(file) {
  const reader = new FileReader();
  reader.onload = async (e) => {
    car_edit.value.image_name1 = e.target.result;
  };
  reader.readAsDataURL(file.target.files[0]);

}

function imageChanged2edit(file) {
  const reader = new FileReader();
  reader.onload = async (e) => {
    car_edit.value.image_name2 = e.target.result;
  };
  reader.readAsDataURL(file.target.files[0]);

}

function imageChanged3edit(file) {
  const reader = new FileReader();
  reader.onload = async (e) => {
    car_edit.value.image_name3 = e.target.result;
  };
  reader.readAsDataURL(file.target.files[0]);

}

async function add_data(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  if(!car.value.idv6){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอก ID V6",
        icon: "warning",
    });
  }else if(!car.value.tb1){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกทะเบียนรถ",
        icon: "warning"
    });
  }else if(!car.value.tb2){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาเลือกจังหวัดทะเบียน",
        icon: "warning"
    });
  }else if(!car.value.tel){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกเบอร์โทรศัพท์",
        icon: "warning"
    });
  }else if(!car.value.status){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาเลือกสถานะ",
        icon: "warning"
    });
  }else if(!car.value.carnum){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกหมายเลขตัวรถ",
        icon: "warning"
    });
  }else if(!car.value.image_name1){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาแนบ ไฟล์รูป (ป้ายภาษีรถใหม่)",
        icon: "warning"
    });
  }else if(!car.value.image_name2){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาแนบ ไฟล์รูป (คู่มือจดทะเบียนรถ เฉพาะหน้าที่แจ้งแก้ไขเปลี่ยนแปลงป้ายทะเบียน)",
        icon: "warning"
    });
  }else{
    const res = await axios.post(`insertcartbchange`,{
      idv6: car.value.idv6,
      tb1ori: car.value.tb1ori,
      tb2ori: car.value.tb2ori,
      tb1: car.value.tb1,
      tb2: car.value.tb2,
      tel: car.value.tel,
      status: car.value.status,
      carnum: car.value.carnum,
      image_name1: car.value.image_name1,
      image_name2: car.value.image_name2,
      image_name3: car.value.image_name3,
      dctype: car.value.dctype,
    });
    if(res.data.status == 1){
      closeModalIN();
      datacar.value = [];
      statuscar.value = [];
      getdata_car();
      car.value.idv6 = '';
      car.value.tb1 = '';
      car.value.tb2 = '';
      car.value.tb1ori = '';
      car.value.tb2ori = '';
      car.value.tel = '';
      car.value.status = '';
      car.value.carnum = '';
      car.value.image_name = '';
      car.value.dctype = '';
      Swal.fire({
          title: 'สำเร็จ',
          text: res.data.datas,
          icon: "success"
      });
    }else if(res.data.status == 2){
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: res.data.datas,
          icon: "error"
      });
    }else{
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: res.data.datas,
          icon: "error"
      });
    }
  }
}

function get_edit_data(data){
  car_edit.value.id = data[0];
  car_edit.value.iddata = data[1];
  car_edit.value.idv6 = data[2];
  car_edit.value.tb1 = data[6];
  car_edit.value.tb2 = data[7];
  car_edit.value.tb1ori = data[4];
  car_edit.value.tb2ori = data[5];
  car_edit.value.tel = data[11];
  car_edit.value.carnum = data[3];
  if(data[15] == 'อยู่ระหว่างดำเนินการ'){ car_edit.value.status =1; car_edit.value.status_ori =1;}
  else if(data[15] == 'เชื่อมต่อข้อมูลสำเร็จ'){ car_edit.value.status =2; car_edit.value.status_ori =2;}
  else if(data[15] == 'ขอข้อมูลเพิ่มเติม'){ car_edit.value.status =3; car_edit.value.status_ori =3; }
  else if(data[15] == 'สถานะเปลี่ยนป้ายไม่สำเร็จ'){ car_edit.value.status =4; car_edit.value.status_ori =4; }
  else if(data[15] == 'ตรวจสอบข้อมูลแล้วรอการเชื่อมต่อ'){ car_edit.value.status =5; car_edit.value.status_ori =5; }
  car_edit.value.image_name1_ori = data[8];
  car_edit.value.image_name2_ori = data[9];
  car_edit.value.image_name3_ori = data[10];
  if(data[12] == 'อยู่ในโครงการบัตรส่วนลด'){ car_edit.value.dctype =1;}
  else if(data[12] == 'ไม่อยู่ในโครงการบัตรส่วนลด'){ car_edit.value.dctype =0;}
  openModalED()
}

async function edit_data(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  if(!car_edit.value.idv6){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอก ID V6",
        icon: "warning",
    });
  }else if(!car_edit.value.tb1){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกทะเบียนรถ",
        icon: "warning"
    });
  }else if(!car_edit.value.tb2){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาเลือกจังหวัดทะเบียน",
        icon: "warning"
    });
  }else if(!car_edit.value.tel){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกเบอร์โทรศัพท์",
        icon: "warning"
    });
  }else if(!car_edit.value.status){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาเลือกสถานะ",
        icon: "warning"
    });
  }else if(!car_edit.value.carnum){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกหมายเลขตัวรถ",
        icon: "warning"
    });
  }else{
    const res = await axios.post(`editcartbchange`,{
      idv6: car_edit.value.idv6,
      iddata: car_edit.value.iddata,
      tb1ori: car_edit.value.tb1ori,
      tb2ori: car_edit.value.tb2ori,
      tb1: car_edit.value.tb1,
      tb2: car_edit.value.tb2,
      tel: car_edit.value.tel,
      status: car_edit.value.status,
      status_ori: car_edit.value.status_ori,
      carnum: car_edit.value.carnum,
      image_name1: car_edit.value.image_name1,
      image_name2: car_edit.value.image_name2,
      image_name3: car_edit.value.image_name3,
      dctype: car_edit.value.dctype,
      id: car_edit.value.id,
    });
    if(res.data.status == 1){
      closeModalED();
      datacar.value = [];
      statuscar.value = [];
      getdata_car();
      car_edit.value.idv6 = '';
      car_edit.value.tb1ori = '';
      car_edit.value.tb2ori = '';
      car_edit.value.tb1 = '';
      car_edit.value.tb2 = '';
      car_edit.value.tel = '';
      car_edit.value.status = '';
      car_edit.value.carnum = '';
      car_edit.value.image_name1 = '';
      car_edit.value.image_name2 = '';
      car_edit.value.image_name3 = '';
      car_edit.value.dctype = '';
      Swal.fire({
          title: 'สำเร็จ',
          text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
          icon: "success"
      });
    }else if(res.data.status == 2){
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: "ทะเบียนรถซ้ำ",
          icon: "error"
      });
    }else{
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: "แก้ไขข้อมูลไม่สำเร็จ",
          icon: "error"
      });
    }
  }
  closeModalED()
}

function del_data(){
  if(dataselect.value.dt.rows({ selected: true }).count() >= 1){
    Swal.fire({
      title: "คำเตือน",
      text: "ต้องการลบข้อมูลที่เลือกหรือไม่ ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ต้องการลบ"
    }).then( async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        Swal.showLoading()
        let dataselectf = dataselect.value.dt.rows({ selected: true }).data();
        var idarray = [];
        for (let index = 0; index < dataselectf.length; index++) {
          idarray.push("'"+dataselectf[index][0]+"'");
        }
        const res = await axios.post(`del/cartbch`,{
          idarray: idarray,
        });
        if(res.data.status == 1){
          datacar.value = [];
          statuscar.value = [];
          getdata_car();
          Swal.fire({
            title: "สำเร็จ",
            text: "ข้อมูลถูกลบเรียบร้อยแล้ว",
            icon: "success"
          });
        }else{
          Swal.fire({
            title: "ไม่สำเร็จ",
            text: "เกิดข้อผิดพลาด",
            icon: "error"
          });
        }
      }
    });
  }else{
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณาเลือกข้อมูลที่ต้องการลบในตารางก่อน !",
      icon: "error"
    });
  }
}

function edit_data_status() {
  if(!car_edit_status.value){
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณาเลือกสถานะก่อน !",
      icon: "warning"
    });
  }else if(car_edit_status.value > 20){
    Swal.fire({
      title: "คำเตือน",
      text: "จำนวนข้อมูลรถที่เลือกเปลี่ยนสถานะเกิน 20 รายการ !",
      icon: "warning"
    });
  }else if(dataselect.value.dt.rows({ selected: true }).count() >= 1){
    Swal.fire({
      title: "คำเตือน",
      text: "ต้องการแก้ไขข้อมูลที่เลือกหรือไม่ ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ต้องการแก้ไข"
    }).then( async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        Swal.showLoading()
        let dataselectf = dataselect.value.dt.rows({ selected: true }).data();
        var idarray = [];
        var telarray = [];
        var tb1array = [];
        var tb2array = [];
        var iddataarray = [];
        for (let index = 0; index < dataselectf.length; index++) {
          idarray.push("'"+dataselectf[index][0]+"'");
          telarray.push(dataselectf[index][10]);
          tb1array.push(dataselectf[index][4]);
          tb2array.push(dataselectf[index][5]);
          iddataarray.push(dataselectf[index][1]);
        }
        const res = await axios.post(`editcar/cartbch`,{
          idarray: idarray,
          telarray: telarray,
          tb1array: tb1array,
          tb2array: tb2array,
          iddataarray: iddataarray,
          status:car_edit_status.value
        });
        if(res.data.status == 1){
          datacar.value = [];
          statuscar.value = [];
          car_edit_status.value = '';
          getdata_car();
          closeModalET()
          Swal.fire({
            title: "สำเร็จ",
            text: "แก้ไขข้อมูลเรียบร้อยแล้ว",
            icon: "success"
          });
        }else{
          Swal.fire({
            title: "ไม่สำเร็จ",
            text: "เกิดข้อผิดพลาด",
            icon: "error"
          });
        }
      }
    });
  }else{
    closeModalET()
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณาเลือกข้อมูลที่ต้องการแก้ไขในตารางก่อน !",
      icon: "error"
    });
  }
}

async function getv6() {
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  if(!car.value.idv6){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอก ID V6",
        icon: "warning",
    });
  }else{
    const res = await axios.post(`getcarv6`,{
      idv6: car.value.idv6,

    });
    if(res.data.status == 1){
      car.value.carnum = res.data.data1;
      car.value.tb1ori = res.data.data2;
      car.value.tb2ori = res.data.data3;
      Swal.fire({
        title: 'เรียบร้อย',
        text: "พบข้อมูล",
        icon: "success",
      });
    }else if(res.data.status == 2){
      Swal.fire({
        title: 'ผิดพลาด',
        text: "ไม่พบข้อมูล",
        icon: "error",
      });
    }else{
      Swal.fire({
        title: 'ผิดพลาด',
        text: "เชื่อมข้อมูลไม่ได้",
        icon: "error",
      });
    }
  }
}

const options = ref({
  dom: '<"top"<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {
          extend: 'copyHtml5',
          text: '<i class="fas fa-file-alt text-lg"></i>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<i class="fas fa-file-excel text-lg"></i>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<i class="fas fa-file-csv text-lg"></i>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<i class="fas fa-file-pdf text-lg"></i>',
          titleAttr: 'PDF'
      }
  ],
  pageLength: 10,
  responsive: true,
  select: {
        style: 'multi',
        selector: 'td:not(:nth-child(7))',
    },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"] ],
  order: [[1, 'DESC']],
  language: dataTextTH,
  columnDefs: [
    {
        data: null,
        render: function (data) {
          let buttondata = '';
          if(data[10] == 'ไม่มีรูปที่แนบ'){
            buttondata = '<button type="button" class="btn btn-warning btn-sm"">แก้ไข</button> <a class="btn btn-primary btn-sm" href="'+ data[8] +'" target="_blank">ดูรูป (ป้ายภาษี)</a> <a class="btn btn-info btn-sm" href="'+ data[9] +'" target="_blank">ดูรูป (คู่มือจดทะเบียนรถ)</a> <a class="btn btn-success btn-sm" disabled>ไม่มีรูปที่แนบ</a>';
          }else{
            buttondata = '<button type="button" class="btn btn-warning btn-sm"">แก้ไข</button> <a class="btn btn-primary btn-sm" href="'+ data[8] +'" target="_blank">ดูรูป (ป้ายภาษีรถใหม่)</a> <a class="btn btn-info btn-sm" href="'+ data[9] +'" target="_blank">ดูรูป (คู่มือจดทะเบียนรถ)</a> <a class="btn btn-success btn-sm" href="'+ data[10] +'" target="_blank">ดูรูป (คูปองบัตรส่วนลด)</a>';
          }
          return buttondata;
        },
        targets: -1
    }
    ]
});
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card
              title="จำนวนรถที่แจ้งเปลี่ยนป้าย"
              :value= "{
                text: (statuscar[0]+statuscar[1]+statuscar[2]+statuscar[3]),
              }"
              :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ (100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
              :icon="{
                component: 'fas fa-car-side',
                background: 'bg-gradient-info',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card
              title="จำนวนรถที่เชื่อมต่อข้อมูลสำเร็จ"
              :value= "{
                text: statuscar[1],
              }"
              :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar[1] / (statuscar[0]+statuscar[1]+statuscar[2]+statuscar[3])) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
              :icon="{
                component: 'fas fa-car-side',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card
              title="จำนวนรถที่อยู่ระหว่างดำเนินการ"
              :value= "{
                text: statuscar[0],
              }"
              :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar[0] / (statuscar[0]+statuscar[1]+statuscar[2]+statuscar[3])) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
              :icon="{
                component: 'fas fa-car-side',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card
              title="จำนวนรถที่ขอข้อมูลเพิ่มเติม"
              :value= "{
                text: statuscar[2],
              }"
              :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar[2] / (statuscar[0]+statuscar[1]+statuscar[2]+statuscar[3])) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
              :icon="{
                component: 'fas fa-car-side',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card
              title="จำนวนรถที่มีสถานะเปลี่ยนป้ายไม่สำเร็จ"
              :value= "{
                text: statuscar[3],
              }"
              :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar[3] / (statuscar[0]+statuscar[1]+statuscar[2]+statuscar[3])) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
              :icon="{
                component: 'fas fa-car-side',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
        <!-- model  -->
        <div class="modal fade" id="model-in" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                      <b class="text-ptt-26">เพิ่มข้อมูลรถ</b>
                      <button type="button" @click="closeModalIN()" class="close-btn">&times;</button>
                  </div>
                  <div class="modal-body">
                    <div class="col-sm-12 mb-3">
                      <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">ID V6</label> <span><a class="text-primary" type="button" @click="getv6()"><b>คลิกเพื่มข้อมูล (รถเปลี่ยนป้าย)</b></a></span>
                        <input type="text" class="form-control" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" id="exampleFormControlInput1" placeholder="กรุณากรอก ID V6" v-model="car.idv6">
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">เลขทะเบียนรถเดิม</label>
                        <input v-if="car.tb1ori" type="text" class="form-control" placeholder="ทะเบียนรถเดิม" :value="car.tb1ori + ' ' + car.tb2ori" readonly>
                        <input v-else type="text" class="form-control" placeholder="ทะเบียนรถเดิม" readonly>
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput2" class="form-label">ทะเบียนรถใหม่</label>
                        <input type="text" class="form-control" id="exampleFormControlInput2" onkeypress="return /[0-9a-zA-Zก-ฎฐ-๙]/i.test(event.key)" maxlength="30" placeholder="กรุณากรอกทะเบียนรถ" v-model="car.tb1">
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput3" class="form-label">จังหวัดทะเบียนรถใหม่</label>
                        <select id="exampleFormControlInput3" class="form-select" v-model="car.tb2">
                          <option value="">เลือกจังหวัด</option>
                          <option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
                          <option value="กระบี่">กระบี่</option>
                          <option value="กาญจนบุรี">กาญจนบุรี</option>
                          <option value="กาฬสินธุ์">กาฬสินธุ์</option>
                          <option value="กำแพงเพชร">กำแพงเพชร</option>
                          <option value="ขอนแก่น">ขอนแก่น</option>
                          <option value="จันทบุรี">จันทบุรี</option>
                          <option value="ฉะเชิงเทรา">ฉะเชิงเทรา</option>
                          <option value="ชัยนาท">ชัยนาท</option>
                          <option value="ชัยภูมิ">ชัยภูมิ</option>
                          <option value="ชุมพร">ชุมพร</option>
                          <option value="ชลบุรี">ชลบุรี</option>
                          <option value="เชียงใหม่">เชียงใหม่</option>
                          <option value="เชียงราย">เชียงราย</option>
                          <option value="ตรัง">ตรัง</option>
                          <option value="ตราด">ตราด</option>
                          <option value="ตาก">ตาก</option>
                          <option value="นครนายก">นครนายก</option>
                          <option value="นครปฐม">นครปฐม</option>
                          <option value="นครพนม">นครพนม</option>
                          <option value="นครราชสีมา">นครราชสีมา</option>
                          <option value="นครศรีธรรมราช">นครศรีธรรมราช</option>
                          <option value="นครสวรรค์">นครสวรรค์</option>
                          <option value="นราธิวาส">นราธิวาส</option>
                          <option value="น่าน">น่าน</option>
                          <option value="นนทบุรี">นนทบุรี</option>
                          <option value="บึงกาฬ">บึงกาฬ</option>
                          <option value="บุรีรัมย์">บุรีรัมย์</option>
                          <option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์</option>
                          <option value="ปทุมธานี">ปทุมธานี</option>
                          <option value="ปราจีนบุรี">ปราจีนบุรี</option>
                          <option value="ปัตตานี">ปัตตานี</option>
                          <option value="พะเยา">พะเยา</option>
                          <option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา</option>
                          <option value="พังงา">พังงา</option>
                          <option value="พิจิตร">พิจิตร</option>
                          <option value="พิษณุโลก">พิษณุโลก</option>
                          <option value="เพชรบุรี">เพชรบุรี</option>
                          <option value="เพชรบูรณ์">เพชรบูรณ์</option>
                          <option value="แพร่">แพร่</option>
                          <option value="พัทลุง">พัทลุง</option>
                          <option value="ภูเก็ต">ภูเก็ต</option>
                          <option value="มหาสารคาม">มหาสารคาม</option>
                          <option value="มุกดาหาร">มุกดาหาร</option>
                          <option value="แม่ฮ่องสอน">แม่ฮ่องสอน</option>
                          <option value="ยโสธร">ยโสธร</option>
                          <option value="ยะลา">ยะลา</option>
                          <option value="ร้อยเอ็ด">ร้อยเอ็ด</option>
                          <option value="ระนอง">ระนอง</option>
                          <option value="ระยอง">ระยอง</option>
                          <option value="ราชบุรี">ราชบุรี</option>
                          <option value="ลพบุรี">ลพบุรี</option>
                          <option value="ลำปาง">ลำปาง</option>
                          <option value="ลำพูน">ลำพูน</option>
                          <option value="เลย">เลย</option>
                          <option value="ศรีสะเกษ">ศรีสะเกษ</option>
                          <option value="สกลนคร">สกลนคร</option>
                          <option value="สงขลา">สงขลา</option>
                          <option value="สมุทรสาคร">สมุทรสาคร</option>
                          <option value="สมุทรปราการ">สมุทรปราการ</option>
                          <option value="สมุทรสงคราม">สมุทรสงคราม</option>
                          <option value="สระแก้ว">สระแก้ว</option>
                          <option value="สระบุรี">สระบุรี</option>
                          <option value="สิงห์บุรี">สิงห์บุรี</option>
                          <option value="สุโขทัย">สุโขทัย</option>
                          <option value="สุพรรณบุรี">สุพรรณบุรี</option>
                          <option value="สุราษฎร์ธานี">สุราษฎร์ธานี</option>
                          <option value="สุรินทร์">สุรินทร์</option>
                          <option value="สตูล">สตูล</option>
                          <option value="หนองคาย">หนองคาย</option>
                          <option value="หนองบัวลำภู">หนองบัวลำภู</option>
                          <option value="อำนาจเจริญ">อำนาจเจริญ</option>
                          <option value="อุดรธานี">อุดรธานี</option>
                          <option value="อุตรดิตถ์">อุตรดิตถ์</option>
                          <option value="อุทัยธานี">อุทัยธานี</option>
                          <option value="อุบลราชธานี">อุบลราชธานี</option>
                          <option value="อ่างทอง">อ่างทอง</option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput4" class="form-label">เบอร์โทรศัพท์</label>
                        <input type="text" class="form-control" onkeypress="return /[0-9]/i.test(event.key)" id="exampleFormControlInput4" placeholder="กรุณากรอก เบอร์โทรศัพท์" v-model="car.tel">
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInpute5" class="form-label">สถานะ</label>
                        <select id="exampleFormControlInpute5" class="form-select" v-model="car.status">
                            <option value="" selected>เลือกสถานะ</option>
                            <option value="1">อยู่ระหว่างดำเนินการ</option>
                            <option value="2">เชื่อมต่อข้อมูลสำเร็จ</option>
                            <option value="3">ขอข้อมูลเพิ่มเติม</option>
                            <option value="4">สถานะเปลี่ยนป้ายไม่สำเร็จ</option>
                            <option value="5">ตรวจสอบข้อมูลแล้วรอการเชื่อมต่อ</option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput6" class="form-label">หมายเลขตัวถังรถ</label>
                        <input type="text" class="form-control" id="exampleFormControlInput6" placeholder="กรุณากรอก หมายเลขตัวถังรถ" v-model="car.carnum">
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput7" class="form-label">ไฟล์รูป (ป้ายภาษีทะเบียนใหม่)</label>
                        <input type="file" class="form-control" id="exampleFormControlInput7" accept="image/*" @change="imageChanged1($event)">
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput8" class="form-label">ไฟล์รูป (คู่มือจดทะเบียนรถ เฉพาะหน้าที่แจ้งแก้ไขเปลี่ยนแปลงป้ายทะเบียน)</label>
                        <input type="file" class="form-control" id="exampleFormControlInput8" accept="image/*" @change="imageChanged2($event)">
                      </div>
                      <div class="mb-3 text-center d-flex justify-content-center">
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexCheckChecked1" :true-value="1" :false-value="0" v-model="car.dctype">
                            <label class="form-check-label text-danger" for="flexCheckChecked1">อยู่ในโครงการบัตรส่วนลด</label>
                          </div>
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput8" class="form-label">แนบรูปบัตรส่วนลด (กรณีอยู่ในโครงการบัตรส่วนลด)</label>
                        <input type="file" class="form-control" id="exampleFormControlInput9" accept="image/*" @change="imageChanged3($event)">
                      </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" @click="add_data()" class="btn btn-info border-radius-ptt" style="font-size: 20px;">บันทึกข้อมูล</button>
                    <button type="button" @click="closeModalIN()" class="btn btn-danger border-radius-ptt" style="font-size: 20px;">ปิด</button>
                </div>
              </div>
          </div>
        </div>
        <!-- model edit status  -->
        <div class="modal fade" id="model-et" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <b class="text-ptt-26">แก้ไขสถานะข้อมูล</b>
                        <button type="button" @click="closeModalET()" class="close-btn">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="col-sm-12 mb-3">
                            <div class="mb-3">
                              <label for="exampleFormControlInpute3" class="form-label">สถานะที่ต้องการแก้ไข</label>
                              <select id="exampleFormControlInpute3" class="form-select" v-model="car_edit_status">
                                  <option value="" selected>เลือกสถานะ</option>
                                  <option value="1">อยู่ระหว่างดำเนินการ</option>
                                  <option value="2">เชื่อมต่อข้อมูลสำเร็จ</option>
                                  <option value="3">ขอข้อมูลเพิ่มเติม</option>
                                  <option value="4">ไม่ใช่รถสถานะแจ้งเปลี่ยนป้าย</option>
                                  <option value="5">ตรวจสอบข้อมูลแล้วรอการเชื่อมต่อ</option>
                              </select>
                          </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                      <button type="button" @click="edit_data_status()" class="btn btn-info border-radius-ptt" style="font-size: 20px;">บันทึกข้อมูล</button>
                        <button type="button" @click="closeModalET()" class="btn btn-danger border-radius-ptt" style="font-size: 20px;">ปิด</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- model edit  -->
        <div class="modal fade" id="model-ed" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <b class="text-ptt-26">แก้ไขข้อมูลรถแจ้งเปลี่ยนป้ายของ ID {{ car_edit.iddata }}</b>
                        <button type="button" @click="closeModalED()" class="close-btn">&times;</button>
                    </div>
                    <div class="modal-body">
                    <div class="col-sm-12 mb-3">
                      <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">ID V6</label>
                        <input type="text" class="form-control" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" id="exampleFormControlInput1" placeholder="กรุณากรอก ID V6" v-model="car_edit.idv6" readonly>
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">เลขทะเบียนรถเดิม</label>
                        <input type="text" class="form-control" placeholder="ทะเบียนรถเดิม" :value="car_edit.tb1ori + ' ' + car_edit.tb2ori" readonly>
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput2" class="form-label">ทะเบียนรถใหม่</label>
                        <input type="text" class="form-control" id="exampleFormControlInput2" onkeypress="return /[0-9a-zA-Zก-ฎฐ-๙]/i.test(event.key)" maxlength="30" placeholder="กรุณากรอกทะเบียนรถ" v-model="car_edit.tb1">
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput3" class="form-label">จังหวัดทะเบียนรถใหม่</label>
                        <select id="exampleFormControlInput3" class="form-select" v-model="car_edit.tb2">
                          <option value="" selected>เลือกจังหวัด</option>
                          <option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
                          <option value="กระบี่">กระบี่</option>
                          <option value="กาญจนบุรี">กาญจนบุรี</option>
                          <option value="กาฬสินธุ์">กาฬสินธุ์</option>
                          <option value="กำแพงเพชร">กำแพงเพชร</option>
                          <option value="ขอนแก่น">ขอนแก่น</option>
                          <option value="จันทบุรี">จันทบุรี</option>
                          <option value="ฉะเชิงเทรา">ฉะเชิงเทรา</option>
                          <option value="ชัยนาท">ชัยนาท</option>
                          <option value="ชัยภูมิ">ชัยภูมิ</option>
                          <option value="ชุมพร">ชุมพร</option>
                          <option value="ชลบุรี">ชลบุรี</option>
                          <option value="เชียงใหม่">เชียงใหม่</option>
                          <option value="เชียงราย">เชียงราย</option>
                          <option value="ตรัง">ตรัง</option>
                          <option value="ตราด">ตราด</option>
                          <option value="ตาก">ตาก</option>
                          <option value="นครนายก">นครนายก</option>
                          <option value="นครปฐม">นครปฐม</option>
                          <option value="นครพนม">นครพนม</option>
                          <option value="นครราชสีมา">นครราชสีมา</option>
                          <option value="นครศรีธรรมราช">นครศรีธรรมราช</option>
                          <option value="นครสวรรค์">นครสวรรค์</option>
                          <option value="นราธิวาส">นราธิวาส</option>
                          <option value="น่าน">น่าน</option>
                          <option value="นนทบุรี">นนทบุรี</option>
                          <option value="บึงกาฬ">บึงกาฬ</option>
                          <option value="บุรีรัมย์">บุรีรัมย์</option>
                          <option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์</option>
                          <option value="ปทุมธานี">ปทุมธานี</option>
                          <option value="ปราจีนบุรี">ปราจีนบุรี</option>
                          <option value="ปัตตานี">ปัตตานี</option>
                          <option value="พะเยา">พะเยา</option>
                          <option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา</option>
                          <option value="พังงา">พังงา</option>
                          <option value="พิจิตร">พิจิตร</option>
                          <option value="พิษณุโลก">พิษณุโลก</option>
                          <option value="เพชรบุรี">เพชรบุรี</option>
                          <option value="เพชรบูรณ์">เพชรบูรณ์</option>
                          <option value="แพร่">แพร่</option>
                          <option value="พัทลุง">พัทลุง</option>
                          <option value="ภูเก็ต">ภูเก็ต</option>
                          <option value="มหาสารคาม">มหาสารคาม</option>
                          <option value="มุกดาหาร">มุกดาหาร</option>
                          <option value="แม่ฮ่องสอน">แม่ฮ่องสอน</option>
                          <option value="ยโสธร">ยโสธร</option>
                          <option value="ยะลา">ยะลา</option>
                          <option value="ร้อยเอ็ด">ร้อยเอ็ด</option>
                          <option value="ระนอง">ระนอง</option>
                          <option value="ระยอง">ระยอง</option>
                          <option value="ราชบุรี">ราชบุรี</option>
                          <option value="ลพบุรี">ลพบุรี</option>
                          <option value="ลำปาง">ลำปาง</option>
                          <option value="ลำพูน">ลำพูน</option>
                          <option value="เลย">เลย</option>
                          <option value="ศรีสะเกษ">ศรีสะเกษ</option>
                          <option value="สกลนคร">สกลนคร</option>
                          <option value="สงขลา">สงขลา</option>
                          <option value="สมุทรสาคร">สมุทรสาคร</option>
                          <option value="สมุทรปราการ">สมุทรปราการ</option>
                          <option value="สมุทรสงคราม">สมุทรสงคราม</option>
                          <option value="สระแก้ว">สระแก้ว</option>
                          <option value="สระบุรี">สระบุรี</option>
                          <option value="สิงห์บุรี">สิงห์บุรี</option>
                          <option value="สุโขทัย">สุโขทัย</option>
                          <option value="สุพรรณบุรี">สุพรรณบุรี</option>
                          <option value="สุราษฎร์ธานี">สุราษฎร์ธานี</option>
                          <option value="สุรินทร์">สุรินทร์</option>
                          <option value="สตูล">สตูล</option>
                          <option value="หนองคาย">หนองคาย</option>
                          <option value="หนองบัวลำภู">หนองบัวลำภู</option>
                          <option value="อำนาจเจริญ">อำนาจเจริญ</option>
                          <option value="อุดรธานี">อุดรธานี</option>
                          <option value="อุตรดิตถ์">อุตรดิตถ์</option>
                          <option value="อุทัยธานี">อุทัยธานี</option>
                          <option value="อุบลราชธานี">อุบลราชธานี</option>
                          <option value="อ่างทอง">อ่างทอง</option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput4" class="form-label">เบอร์โทรศัพท์</label>
                        <input type="text" class="form-control" onkeypress="return /[0-9]/i.test(event.key)" id="exampleFormControlInput4" placeholder="กรุณากรอก เบอร์โทรศัพท์" v-model="car_edit.tel">
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInpute5" class="form-label">สถานะ</label>
                        <select id="exampleFormControlInpute5" class="form-select" v-model="car_edit.status">
                            <option value="" selected>เลือกสถานะ</option>
                            <option value="1">อยู่ระหว่างดำเนินการ</option>
                            <option value="2">เชื่อมต่อข้อมูลสำเร็จ</option>
                            <option value="3">ขอข้อมูลเพิ่มเติม</option>
                            <option value="4">ไม่ใช่รถสถานะแจ้งเปลี่ยนป้าย</option>
                            <option value="5">ตรวจสอบข้อมูลแล้วรอการเชื่อมต่อ</option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput6" class="form-label">หมายเลขตัวถังรถ</label>
                        <input type="text" class="form-control" id="exampleFormControlInput6" placeholder="กรุณากรอก หมายเลขตัวถังรถ" v-model="car_edit.carnum" readonly>
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput7" class="form-label">ไฟล์รูป (ป้ายภาษีทะเบียนใหม่) <span><a class="text-primary" :href="car_edit.image_name1_ori" target="_blank">คลิกเพื่อดูรูปเดิมที่แนบไว้</a></span></label>
                        <input type="file" class="form-control" id="exampleFormControlInput7" accept="image/*" @change="imageChanged1edit($event)">
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput8" class="form-label">ไฟล์รูป (คู่มือจดทะเบียนรถ เฉพาะหน้าที่แจ้งแก้ไขเปลี่ยนแปลงป้ายทะเบียน) <span><a class="text-primary" :href="car_edit.image_name2_ori" target="_blank">คลิกเพื่อดูรูปเดิมที่แนบไว้</a></span></label>
                        <input type="file" class="form-control" id="exampleFormControlInput8" accept="image/*" @change="imageChanged2edit($event)">
                      </div>
                      <div class="mb-3 text-center d-flex justify-content-center">
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexCheckChecked2" :true-value="1" :false-value="0" v-model="car_edit.dctype">
                            <label class="form-check-label text-danger" for="flexCheckChecked2">อยู่ในโครงการบัตรส่วนลด</label>
                          </div>
                      </div>
                      <div class="mb-3">
                        <label for="exampleFormControlInput8" class="form-label">แนบรูปบัตรส่วนลด (กรณีอยู่ในโครงการบัตรส่วนลด) <span v-if="car_edit.image_name3_ori != 'ไม่มีรูปที่แนบ'"><a class="text-primary" :href="car_edit.image_name3_ori" target="_blank">คลิกเพื่อดูรูปเดิมที่แนบไว้</a></span><span v-else>ไม่มีรูปที่แนบ</span></label>
                        <input type="file" class="form-control" id="exampleFormControlInput9" accept="image/*" @change="imageChanged3edit($event)">
                      </div>
                  </div>
                </div>
                    <div class="modal-footer">
                      <button type="button" @click="edit_data()" class="btn btn-info border-radius-ptt" style="font-size: 20px;">บันทึกข้อมูล</button>
                        <button type="button" @click="closeModalED()" class="btn btn-danger border-radius-ptt" style="font-size: 20px;">ปิด</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- table  -->
        <div class="row mt-4">
          <div class="col-lg-12 mb-lg-0 mb-4">
            <div class="card">
              <div class="card-header text-center">
                <h5>ตารางข้อมูลการแจ้งแก้ไขรถที่แจ้งเปลี่ยนป้ายทั้งหมด</h5>
                <p>ในการลบข้อมูลสามารถคลิกที่ข้อมูลที่ต้องการ แก้ไขสถานะหรือลบได้พร้อมกันหลายๆข้อมูล</p>
                <button class="btn btn-primary me-2" type="button" @click="openModalIN()">เพิ่มข้อมูล</button>
                <button class="btn btn-info me-2" type="button" @click="openModalET()">แก้ไขสถานะ</button>
                <button class="btn btn-danger" type="button" @click="del_data()">ลบข้อมูล</button>
              </div>
              <div class="card-body">
                <DataTable :data="datacar" :options="options" class="table table-hover" width="100%" ref="dataselect">
                  <thead>
                      <tr>
                        <th class="none">Hash ID</th>
                        <th>ไอดี</th>
                        <th>ไอดี V6</th>
                        <th>หมายเลขตัวถังรถ</th>
                        <th>ทะเบียนรถเดิม</th>
                        <th>จังหวัดทะเบียนรถเดิม</th>
                        <th>ทะเบียนรถใหม่</th>
                        <th>จังหวัดทะเบียนรถใหม่</th>
                        <th class="none">ไฟล์รูป (ป้ายภาษีรถใหม่)</th>
                        <th class="none">ไฟล์รูป (คู่มือจดทะเบียนรถ)</th>
                        <th class="none">ไฟล์รูป (คูปองบัตรส่วนลด)</th>
                        <th class="none">เบอร์โทรศัพท์</th>
                        <th>บัตรส่วนลด</th>
                        <th>เพิ่มข้อมูลเมื่อ</th>
                        <th class="none">อัพเดทเมื่อ</th>
                        <th>สถานะรายการ</th>
                        <th>จัดการ</th>
                      </tr>
                  </thead>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
     
      </div>
    </div>
  </div>
</template>

<style>
@import 'datatables.net-bs5';
@import 'datatables.net-buttons-bs5';
@import 'datatables.net-responsive-bs5';
@import 'datatables.net-select-bs5';
div.dt-container div.dt-length select {
    width: 4em;
}

table.dataTable th.dt-type-numeric {
  text-align: left;
}
table.dataTable td.dt-type-numeric {
  text-align: left;
}
.table-center {
  text-align: center;
}
div.dt-container div.dt-search input{
  width: 180px;
}
.pagination {
  justify-content: center !important;
}
@media only screen and (min-width: 768px) {
  .left-col {
      float: left;
      width: 40%;
  }
  .center-col {
      float: left;
      width: 25%;
  }
  .right-col {
      float: left;
      width: 35%;
  }
}
.page-link {
  width: 50px;
  height: 50px;
}
.close-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-size: 26px;
}
</style>
